import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import { Helmet } from "react-helmet"
import {isMobile} from 'react-device-detect'
import { Link } from "gatsby"

export default function error404() {
  return (

    <PageTransition
    transitionStyles={{
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
    }}
  >
      <div className="wrapper">

        <Helmet>
          <meta charSet="utf-8" />
          <title>404 - Governors Point</title>
          <link rel="canonical" href="https://governorspoint.com" />
          <body className={isMobile ? `dark mobile` : `dark`} />
        </Helmet>

        <Header menuActive="" />

        <div className="main">

          <div className="container-inner">

            <div className="image-copy-width">
              <p>404</p>
              <p>The page you are looking for cannot be found.</p>
              <p className="mt-50"><Link to="/">Home</Link></p>
            </div>

          </div>
        </div>

        <Footer />

      </div>
    </PageTransition>

  )
}
